import { Account } from "@radixdlt/radix-dapp-toolkit";
import {
	json,
	type LoaderFunctionArgs,
	type MetaFunction
} from "@remix-run/cloudflare";
import { Form, useFetcher, useLoaderData } from "@remix-run/react";
import { useState } from "react";
import AccountSelector from "~/components/account-selector";
import TransactionButton from "~/components/transaction-button";
import useInterval from "~/hooks/use-interval";
import {
	fetchBotAPY,
	fetchDefiPlazaAPY,
	fetchOciswapAPY,
	fetchRootAPY,
	fetchStakingAPY,
	fetchValidatorAPY,
} from "~/lib/apy";
import { cache } from "~/lib/cache";
import { setDbFromContext } from "~/lib/db";
import {
	generateClaimAndStakeManifest,
	generateClaimManifest,
} from "~/lib/manifests";
import { useRadix } from "~/lib/rdt/radixProvider";
import { CLAIM_TYPES, classNames } from "~/lib/util";
import { loader as walletLoader } from "~/routes/wallet.$account";

const RESET_HOUR = 10;

export const meta: MetaFunction = () => {
	return [
		{
			title: "Receive free $HUG",
			description:
				"Receive free $HUG via Proof of Hug activities, like adding liquidity to DefiPlaza and Ociswap",
		},
	];
};

export async function loader({ request, context }: LoaderFunctionArgs) {
	setDbFromContext(context);

	const apys = await cache(context, "https://receive.hug.meme/cache/apys2", 900, async () => {
		const validatorAPY = await fetchValidatorAPY();
		const stakingAPY = await fetchStakingAPY();
		const defiPlazaAPY = await fetchDefiPlazaAPY();
		const ociswapAPY = await fetchOciswapAPY();
		const botAPY = await fetchBotAPY();
		const rootAPY = await fetchRootAPY(context);
		const gameAPY = 0;

		return {
			"1": validatorAPY,
			"2": defiPlazaAPY,
			"3": ociswapAPY,
			"4": stakingAPY,
			"5": botAPY,
			"6": rootAPY,
			"7": gameAPY
		};
	}
	);

	return json({
		entries: CLAIM_TYPES,
		apys,
	});
}

export default function ClaimPage() {
	const { entries, apys } = useLoaderData<typeof loader>();
	const walletFetcher = useFetcher<typeof walletLoader>();
	const [account, setAccount] = useState<string | null>(null);
	const [wenUpcoming, setWenUpcoming] = useState<string | null>(null);
	const [error, setError] = useState<string | null>(null);

	const { sendTransaction } = useRadix();

	function onAccount(account: Account) {
		setAccount(account.address);

		walletFetcher.load(`/wallet/${account.address}`);
		setError("");
	}

	useInterval(() => {
		wenUpcomingHugs();
	}, 1000);

	function wenUpcomingHugs() {
		let date;

		if (!walletFetcher.data?.since) {
			// if we don't have a last updated
			// get the start of the week
			date = new Date();
			const day = date.getDay();
			const diff = date.getDate() - day;

			// set countdown to next week
			if (diff > 0 || diff == 0 && date.getHours() >= RESET_HOUR) {
				date.setDate(diff + 7);
			}
		} else {
			// if we have a last updated, the new reset is always in 7 days
			date = new Date(walletFetcher.data.since);
			date.setDate(date.getDate() + 7);
		}

		date.setHours(RESET_HOUR, 0, 0, 0);

		let secondsDiff = (date.getTime() - new Date().getTime()) / 1000;

		if (secondsDiff < 0) {
			secondsDiff = ((secondsDiff % (7 * 86400)) + 7 * 86400) % (7 * 86400);
		}
		
		setWenUpcoming(`
			${Math.floor(secondsDiff / 86400)} days,
			${Math.floor((secondsDiff % 86400) / 3600)} hours,
			${Math.floor((secondsDiff % 3600) / 60)} minutes,
			${Math.floor(secondsDiff % 60)} seconds
		`);
	}

	async function submitTransaction(event) {
		event.preventDefault();
		setError("");

		if (!account || !walletFetcher.data?.ready) {
			return;
		}

		let total = 0;
		for (let entry of walletFetcher.data?.ready) {
			total += parseFloat(entry.remaining);
		}

		if (total == 0) {
			setError("You don't have any ready HUGs to Claim.");
			return;
		}

		const manifest = generateClaimManifest(
			account,
			walletFetcher.data?.localId,
			walletFetcher.data?.ready
		);

		const txResult = await sendTransaction(
			manifest,
			"Thank you for HUGging with us 🤗"
		);
	}

	async function submitStake(event) {
		event.preventDefault();
		setError("");

		if (!account || !walletFetcher.data?.ready) {
			return;
		}

		let total = 0;
		for (let entry of walletFetcher.data?.ready) {
			total += parseFloat(entry.remaining);
		}

		if (total == 0) {
			setError("You don't have any ready HUGs to Claim.");
			return;
		}

		const manifest = generateClaimAndStakeManifest(
			account,
			walletFetcher.data?.localId,
			walletFetcher.data?.ready
		);

		const txResult = await sendTransaction(
			manifest,
			"Restaking your rewards like a MotherHugger 🚀"
		);
	}

	async function submitSingleTransaction(event, type: string) {
		event.preventDefault();
		setError("");

		if (!account || !walletFetcher.data?.ready) {
			return;
		}

		const ready = walletFetcher.data?.ready.find((e) => e.type == type);

		if (!ready) {
			return;
		}

		let total = ready.remaining || 0;
		// for (let entry of walletFetcher.data?.ready) {
		//    total += parseFloat(entry.remaining);
		// }

		if (total == 0) {
			setError("You don't have any ready HUGs to Claim.");
			return;
		}

		const manifest = generateClaimManifest(
			account,
			walletFetcher.data?.localId,
			[ready]
		);

		const txResult = await sendTransaction(
			manifest,
			"Thank you for HUGging with us 🤗"
		);
	}

	let totalReady = 0,
		totalUpcoming = 0,
		totalEver = 0;

	return (
		<Form
			method="post"
			onSubmit={submitTransaction}
			className="w-full max-w-[1075px] mx-auto"
		>
			<h1 className="text-[60px] bangers text-center text-white font-bold text-left p-8 pb-0 text-orange-800">
				Receive hugs
			</h1>
			<p className="text-orange-800 yaelah text-center text-white p-8 pt-0">
				Participate in{" "}
				<a
					href="https://howto.hug.meme/proof-of-hug/intro-to-poh"
					target="_blank"
					className="underline"
				>
					Proof of HUG
				</a>{" "}
				activities to receive HUG rewards.
			</p>

			<div className="space-y-6 pb-[860px] relative mx-2">
				<AccountSelector mode="light" onAccount={onAccount}></AccountSelector>

				{entries.map((entry) => {
					let readyAmount = 0;
					let everAmount = 0;
					const ready = walletFetcher.data?.ready.find(
						(e) => e.type == entry.type
					);

					if (ready) {
						readyAmount = parseFloat(ready.remaining);
						everAmount = parseFloat(ready.amount);
						totalReady += readyAmount;
						totalEver += everAmount;
					}

					let upcomingAmount = 0;
					const upcoming = walletFetcher.data?.upcoming.find(
						(e) => e.type == entry.type
					);

					if (upcoming) {
						upcomingAmount = upcoming.amount;
						totalUpcoming += upcomingAmount;
					}

					const apy = apys[entry.type] || 0;

					return (
						<section
							key={entry.type}
							className="flex flex-col rounded-lg shadow-xl px-6 py-4 transition duration-500 cursor-pointer object-cover  bg-white/80"
						>
							<h3 className="flex justify-between poppins-semibold items-center mb-4 text-[40px] text-black">
								{entry.value}
								<span className="text-black flex items-center gap-2 poppins-medium text-[18px]">
									{apy ? `APY ${apy}%` : null}
									<a
										href={entry.link}
										type="button"
										target="_blank"
										className="py px-2 ml-3 text-[12px] p-1 text-[#836005] rounded-xl border"
										style={{
											background: "linear-gradient(#FFD501, #FFB600)",
										}}
									>
										{entry.button}
									</a>
									{parseFloat(ready?.remaining || "0") > 0 ? (
										<button
											type="button"
											onClick={(evt) =>
												submitSingleTransaction(evt, entry.type)
											}
											style={{
												background: "linear-gradient(#FFD501, #FFB600)",
											}}
											className="py px-2  text-[#836005] p-1 text-[12px] ml-3 rounded-xl border "
										>
											Claim
										</button>
									) : null}
								</span>
							</h3>
							<div className="grid grid-cols-1 md:grid-cols-3 gap-4">
								<div className="flex flex-col">
									<label
										htmlFor="outputAmount"
										className="text-[#747474] text-[16px]  poppins-medium "
									>
										HUGs ready to receive
									</label>
									<div className="flex flex-wrap justify-between items-center">
										<p
											className={classNames(
												//navigation.state == 'submitting' ? 'animate-pulse bg-gray-200  rounded' : 'bg-transparent',
												"flex-auto w-1/2 md:w-60 mr-4 border-none p-0  bg-transparent text-[#1D8985] poppins-semibold text-[40px]"
											)}
										>
											{Math.floor(readyAmount).toLocaleString()}
										</p>
									</div>
								</div>
								<div className="flex flex-col">
									<label
										htmlFor="outputAmount"
										className="text-[#747474] text-[16px]  poppins-medium "
									>
										Incoming HUGs
									</label>
									<div className="flex flex-wrap justify-between items-center">
										<p
											className={classNames(
												//navigation.state == 'submitting' ? 'animate-pulse bg-gray-200  rounded' : 'bg-transparent',
												"flex-auto w-1/2 md:w-60 mr-4 border-none p-0  text-[#1D8985] poppins-semibold text-[40px] "
											)}
										>
											{Math.floor(upcomingAmount).toLocaleString()}
										</p>
									</div>
								</div>
								<div className="flex flex-col">
									<label
										htmlFor="outputAmount"
										className="text-[#747474] text-[16px]  poppins-medium "
									>
										Lifetime HUGs
									</label>
									<div className="flex flex-wrap justify-between items-center">
										<p
											className={classNames(
												//navigation.state == 'submitting' ? 'animate-pulse bg-gray-200  rounded' : 'bg-transparent',
												"flex-auto w-1/2 md:w-60 mr-4 border-none p-0  text-[#1D8985] poppins-semibold text-[40px] "
											)}
										>
											{Math.floor(everAmount).toLocaleString()}
										</p>
									</div>
								</div>
							</div>
						</section>
					);
				})}

				<section className="flex flex-col rounded-lg shadow-xl px-6 py-4 hover:scale-105 transition duration-500 cursor-pointer object-cover  bg-white/80">
					<h3 className="flex justify-between poppins-semibold items-center mb-4 text-[40px] text-black">
						Total{" "}
					</h3>
					<div className="grid grid-cols-1 md:grid-cols-3 gap-4">
						<div className="flex flex-col">
							<label
								htmlFor="outputAmount"
								className="text-[#747474] text-[16px]  poppins-medium "
							>
								Total HUGs to receive now
							</label>
							<div className="flex flex-wrap justify-between items-center">
								<p
									className={classNames(
										//navigation.state == 'submitting' ? 'animate-pulse bg-gray-200  rounded' : 'bg-transparent',
										"flex-auto w-1/2 md:w-60 mr-4 border-none p-0  text-[#1D8985] poppins-semibold text-[40px] "
									)}
								>
									{Math.floor(totalReady).toLocaleString()}
								</p>
							</div>
						</div>
						<div className="flex flex-col">
							<label
								htmlFor="outputAmount"
								className="text-[#747474] text-[16px]  poppins-medium "
							>
								Incoming HUGs
							</label>
							<div className="flex flex-wrap justify-between items-center">
								<p
									className={classNames(
										//navigation.state == 'submitting' ? 'animate-pulse bg-gray-200  rounded' : 'bg-transparent',
										"flex-auto w-1/2 md:w-60 mr-4 border-none p-0  text-[#1D8985] poppins-semibold text-[40px] "
									)}
								>
									{Math.floor(totalUpcoming).toLocaleString()}
								</p>
							</div>
						</div>
						<div className="flex flex-col">
							<label
								htmlFor="outputAmount"
								className="text-[#747474] text-[16px]  poppins-medium "
							>
								Lifetime HUGs
							</label>
							<div className="flex flex-wrap justify-between items-center">
								<p
									className={classNames(
										//navigation.state == 'submitting' ? 'animate-pulse bg-gray-200  rounded' : 'bg-transparent',
										"flex-auto w-1/2 md:w-60 mr-4 border-none p-0  text-[#1D8985] poppins-semibold text-[40px] "
									)}
								>
									{Math.floor(totalEver).toLocaleString()}
								</p>
							</div>
						</div>
					</div>
				</section>

				<section className="flex flex-col rounded-lg shadow-xl px-6 py-4 hover:scale-105 transition duration-500 cursor-pointer object-cover  bg-white/80">
					<p className="poppins-medium  text-[#747474] text-center">
						Incoming HUGs will be ready in{" "}
						<span className="underline">{wenUpcoming}</span>
					</p>
				</section>

				{error ? (
					<p className="rounded-lg poppins-medium text-[24px] yaelah text-red text-center">
						{error}
					</p>
				) : null}

				{walletFetcher.data && walletFetcher.data.localId && walletFetcher.data?.linkedLocalId && walletFetcher.data.localId !== walletFetcher.data.linkedLocalId ? <p className="rounded-lg poppins-medium text-[24px] yaelah text-red text-center">
					You have Club Badge {walletFetcher.data.localId} in your account, but your rewards are sent to {walletFetcher.data.linkedLocalId}
				</p> : null}

				<TransactionButton
					title="🤗 Receive your rewards"
					onClick={submitTransaction}
				/>

				<p className="mt-12 text-center text-black poppins-medium">
					Stake your rewards instantly!
				</p>

				<TransactionButton
					title="🚀 Receive and Stake your rewards"
					onClick={submitStake}
				/>
				<img
					src="/images/celeb.png"
					className="absolute -bottom-0 left-0 right-0 mx-auto"
				/>
			</div>
		</Form>
	);
}
